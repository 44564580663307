import EditFirewalls from '@/layouts/Stack/components/EditFirewalls.vue';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      data: {},
      ids: [],
      initialNames: null,
      isProcessing: false,
    };
  },
  methods: {
    editFirewalls: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'EditFirewalls';
        this.$modals.open({
          name: selfName,
          size: 'large',
          component: EditFirewalls,
          closeOnBackdrop: false,
          props: {
            tariff: instance,
          },
          on: {
            change: data => {
              this.ids = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            servers: data => {
              this.initialNames = data.map(x => x.name);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  if (this.initialNames) {
                    const addFirewalls = this.ids.filter(x => !this.initialNames.includes(x));
                    const removeFirewalls = this.initialNames.filter(x => !this.ids.includes(x));
                    if (addFirewalls) {
                      let result = addFirewalls.reduce((acc, nextId) => {
                        return acc.then(() => {
                          const payload = {
                            addSecurityGroup: {
                              name: nextId,
                            },
                          };
                          return this.sendServerAction(JSON.stringify(payload));
                        });
                      }, Promise.resolve());
                      result.then(() => {
                        if (removeFirewalls) {
                          let resultRemove = removeFirewalls.reduce((acc, nextId) => {
                            return acc.then(() => {
                              const payload = {
                                removeSecurityGroup: {
                                  name: nextId,
                                },
                              };
                              return this.sendServerAction(JSON.stringify(payload));
                            });
                          }, Promise.resolve());
                          resultRemove.then(() => {
                            setTimeout(() => {
                              this.$store
                                .dispatch('moduleStack/fetchOpenStackRequest', 'servers')
                                .then(() => {
                                  that.$modals.close();
                                  this.showResModal(this.$t('modal.sure.firewalls'));
                                });
                            }, 500 * (addFirewalls.length + removeFirewalls.length));
                          });
                        } else {
                          setTimeout(() => {
                            this.$store
                              .dispatch('moduleStack/fetchOpenStackRequest', 'servers')
                              .then(() => {
                                that.$modals.close();
                                this.showResModal(this.$t('modal.sure.firewalls'));
                              });
                          }, 500 * (addFirewalls.length + removeFirewalls.length));
                        }
                      });
                    }
                    if (removeFirewalls && !addFirewalls) {
                      let resultRemove = removeFirewalls.reduce((acc, nextId) => {
                        return acc.then(() => {
                          const payload = {
                            removeSecurityGroup: {
                              name: nextId,
                            },
                          };
                          return this.sendServerAction(JSON.stringify(payload));
                        });
                      }, Promise.resolve());
                      resultRemove.then(() => {
                        setTimeout(() => {
                          this.$store
                            .dispatch('moduleStack/fetchOpenStackRequest', 'servers')
                            .then(() => {
                              that.$modals.close();
                              this.showResModal(this.$t('modal.sure.firewalls'));
                            });
                        }, 500 * (addFirewalls.length + removeFirewalls.length));
                      });
                    }
                    this.ids = [];
                  } else {
                    const payload = {
                      port: {
                        security_groups: this.ids,
                      },
                      id: this.id,
                      item: 'security_groups',
                    };
                    this.$store
                      .dispatch('moduleStack/updatePort', payload)
                      // this.updateDisk(params)
                      .then(data => {
                        if (data) {
                          this.ids = [];
                          that.$modals.close();
                          this.showResModal(this.$t('modal.sure.firewalls'));
                        }
                      })
                      .catch(e => {
                        this.ids = [];
                        that.$modals.close();
                        // console.log(e.response.data.badRequest.message);
                        this.showError(e.response.data.badRequest.message);
                      });
                  }
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  this.ids = [];
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    sendServerAction(params) {
      this.$store
        .dispatch('moduleStack/setServerAction', {
          id: this.tariff.id,
          params,
        })
        .then(data => {
          return data;
        });
    },
  },
};
